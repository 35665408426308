export const LOADING = 'LOADING'
export const LOADING_SUCCESS = 'LOADING_SUCCESS'

export const ERROR = 'ERROR'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS'

export const CHECK_LANGUAGE = 'CHECK_LANGUAGE'
export const CHECK_LANGUAGE_SUCCESS = 'CHECK_LANGUAGE_SUCCESS'

export const API_spCallServer = 'API_spCallServer'
export const API_spCallServerNoLoading = 'API_spCallServerNoLoading'
export const API_spCallServerNoSQL = 'API_spCallServerNoSQL'
export const API_spCallServerNoSQL_App = 'API_spCallServerNoSQL_App'

export const EncryptString = 'EncryptString'
export const DecryptString = 'DecryptString'
