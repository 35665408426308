export const APIC_spLadingCreate = 'APIC_spLadingCreate'
export const APIC_spLadingGetPrice = 'APIC_spLadingGetPrice'
export const APIC_spLadingGetMany = 'APIC_spLadingGetMany'
export const APIC_spLadingRemove = 'APIC_spLadingRemove'
export const APIC_spLadingGetDataPrint = 'APIC_spLadingGetDataPrint'
export const APIC_spLadingGetById = 'APIC_spLadingGetById'
export const APIC_spLadingGetPriceMany = 'APIC_spLadingGetPriceMany'
export const APIC_spLadingPrintFromExcelAvery = 'APIC_spLadingPrintFromExcelAvery'
export const APIC_LadingUploadExcel = 'APIC_LadingUploadExcel'
export const APIC_spLadingSaveExcel = 'APIC_spLadingSaveExcel'
export const APIC_LadingPrintBillExcel = 'APIC_LadingPrintBillExcel'
export const APIC_spLadingUpdate = 'APIC_spLadingUpdate'
export const APIC_spLadingGet = 'APIC_spLadingGet'
