import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Alerterror, FormatDate, FormatDateJson, GetCookie } from "../../Utils";
import { Lading } from "../../Redux/Actions/Category";
import { APIKey, TOKEN_DEVICE } from "../../Services/Api";
import { useInput } from "../../Hooks";
import LayoutMain from "../../Layout/LayoutMain";

export const LadingPrintFromExcelAveryComponent = () => {
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(true); // disable button
  const [CustomerID, setCustomerID] = useState(GetCookie("CustomerID"));
  const history = useHistory();

  const [ShowList, setShowList] = useState("display-none");
  const [ShowForm, setShowForm] = useState("");
  const [FileUpload, setFileUpload] = useState({ file: {} });
  const [UploadError, setUploadError] = useState("");
  const [LadingList, setLadingList] = useState([]);
  const [TotalSheet, bindTotalSheet, setTotalSheet] = useInput(1);
  const TotalSheetRef = useRef();

  useEffect(() => {
    //GET CUSTOMER INFO FROM COOKIE
    if (CustomerID === null) history.push("/");
  }, []);

  const [ShowUploadFile, setShowUploadFile] = useState("");
  const [ShowListFile, setShowListFile] = useState("display-none");

  const onFileChange = (event) => {
    if (event.target.files !== null) {
      setFileUpload({ file: event.target.files[0] });
      setShowUploadFile("display-none");
      setShowListFile("");
    }
  };

  const onFileUpload = async () => {
    // Create an object of formData
    const formData = new FormData();
    formData.append("CustomerID", CustomerID);
    formData.append("AppAPIKey", APIKey);
    formData.append("TokenDevice", TOKEN_DEVICE);
    // Update the formData object
    formData.append("myFile", FileUpload.file, FileUpload.file.name);

    // Request made to the backend api
    // Send formData object
    const data = await Lading.APIC_LadingPrintBillExcel(formData, dispatch);
    setUploadError(data.localMessage);
    if (data.resultCode === 0) {
      //Alertsuccess("Thành công");
      debugger;
      setLadingList(JSON.parse(data.data));
      console.log("data print", data.data);
      setShowList("");
      setShowForm("display-none");
      setShowUploadFile("");
      setShowListFile("display-none");
      //PaginationTable();
    }
  };

  const [Checked, setChecked] = useState(false);
  const [IsChecked, setIsChecked] = useState(false);
  const [State, setState] = useState([]);

  const handleChange = (e) => {
    let list = [...LadingList];
    let allChecked = IsChecked;
    if (e.target.value === "checkAll") {
      setIsChecked(!IsChecked);
      list.forEach((item) => {
        item.IsPrint = e.target.checked;
        allChecked = e.target.checked;
      });
    } else {
      list.find((item) => item.No === parseInt(e.target.value)).IsPrint =
        e.target.checked;
    }
    setState({ list: LadingList, allChecked: Checked });
  };

  const PrintBill = async () => {
    let _htmlprint = "";
    LadingList.map((item, index) => {
      if (item.IsPrint === true) {
        for (var i = 0; i < parseInt(TotalSheet); i++) {
          _htmlprint += `<div style="width:720px;height: 1050px;page-break-before: always;border:2px solid #000">
                        <table style="width:100%;margin-top: 5px;max-height:90px;">
                            <tbody>
                                <tr>
                                    <td style="vertical-align:top;width:200px;">
                                        <table style="font-size:16px;margin-left:5px;width:200px;">
                                            <tbody>
                                                <tr><td><img src="/assets/img/LogoNetco.png" style="width:180px;"></td></tr>
                                                <tr><td style="padding-left:12px;font-style:italic;font-size:14px;">Ngày in: ${FormatDateJson(
                                                  new Date(),
                                                  "d/M/y H:m"
                                                )}</td></tr>
                                                <tr><td style="padding-left:60px;font-style:italic;font-size:14px;">Liên ${
                                                  i + 1
                                                }</td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                      <table style="width:100%;font-weight:600;text-align:center;">
                                          <tr>
                                              <td><div style="width: 100%;display:inline-block;height:80px;text-align:center;"><div style="font-family: 'Libre Barcode 128 Text';font-size: 80px;font-weight:200">${
                                                item.Bill
                                              }</div></div></td>
                                          </tr>
                                      </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style="width:100%;border-top-style: solid;border-top-width: 1px;">
                            <tbody>
                                <tr>
                                    <td class="table-vertical" style="width:50%;vertical-align:top;border-right-style: dashed;border-right-width: 1px;">
                                        <table style="font-size:18px;">
                                            <tbody>
                                                <tr><td colspan="2"><b>THÔNG TIN NGƯỜI GỬI<b></b></b></td></tr>
                                                <tr><td><b>Công ty: </b>AVERY DENNISON RIS VIET NAM CO., LIMITED -LONG HẬU</td></tr>
                                                <tr><td><b>Địa chỉ: </b>Lot E01 Trung Tam Road, LHIP, Can Giuoc,Long An, Vietnam</td></tr>
                                                <tr><td><b>Điện thoại: </b>028 37763336 Ext.3409 (Mr.Khanh)</td></tr>
                                                <tr><td><b>Ngày gửi: </b>${FormatDate(
                                                  item.Date,
                                                  "d-k-y"
                                                )}</td></tr>
                                                <tr><td style="height:50px;"><b>Ký gửi:</b></td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="table-vertical" style="width:50%;vertical-align: top;padding-left: 5px;">
                                        <table style="font-size:18px;">
                                            <tbody>
                                                <tr><td colspan="2"><b>THÔNG TIN NGƯỜI NHẬN</b></td></tr>
                                                <tr><td><b>Công ty: </b>${
                                                  item.Customer
                                                }</td></tr>
                                                <tr><td style="word-wrap: break-word;max-width: 300px;overflow: hidden;min-height: 100px;"><b>Địa chỉ: </b>${
                                                  item.Address
                                                }</td></tr>
                                                <tr><td><b>ATTN: </b>${
                                                  item.Attn
                                                }</td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table><table style="width:100%;border-top-style: solid;border-top-width: 1px;">
                            <tbody>
                                <tr>
                                    <td class="table-vertical" style="width:50%;vertical-align:top;border-right-style: dashed;border-right-width: 1px;">
                                        <table style="font-size:18px;">
                                            <tbody>
                                                <tr><td colspan="2"><b>THÔNG TIN ĐƠN HÀNG<b></b></b></td></tr>
                                                <tr><td><b>Kí hiệu: </b>${
                                                  item.FileScan
                                                }</td></tr>
                                                <tr><td><b>Số kiện: </b>${
                                                  item.CartonSide
                                                }</td></tr>
                                                <tr><td><b>Số kg: </b>${
                                                  item.Kg
                                                }</td></tr>
                                                <tr><td><b>Số lượng DN: </b>${
                                                  item.TotalDN
                                                }</td></tr>
                                                <tr><td><b>Yêu cầu đặc biệt: </b>${
                                                  item.Keeper
                                                }</td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="table-vertical" style="width:50%;vertical-align: top;padding-left: 5px;">
                                        <table style="font-size:18px;">
                                            <tbody>
                                                <tr><td colspan="2"><b>DỊCH VỤ</b></td></tr>
                                                <tr>   <td><label class="checkbox-inline nowrap"><input type="checkbox">Phát 60 phút</label></td>   <td><label class="checkbox-inline nowrap"><input type="checkbox">PTN</label></td></tr>
                                                <tr>   <td><label class="checkbox-inline nowrap"><input type="checkbox">PT 9:00 AM</label></td>   <td><label class="checkbox-inline nowrap"><input type="checkbox">CPN</label></td></tr>
                                                <tr>   <td><label class="checkbox-inline nowrap"><input type="checkbox">PT 10:00 AM</label></td>   <td><label class="checkbox-inline nowrap"><input type="checkbox">Phát 60h</label></td></tr>
                                                <tr>   <td><label class="checkbox-inline nowrap"><input type="checkbox">PT 12:00 AM</label></td>   <td><label class="checkbox-inline nowrap"><input type="checkbox">Tiết kiệm</label></td></tr>
                                                <tr>   <td><label class="checkbox-inline nowrap"><input type="checkbox">PT 16:00 PM</label></td>   <td><label class="checkbox-inline nowrap"><input type="checkbox">Khác</label></td></tr>
                                                <tr>
                                                    <td><label class="checkbox-inline nowrap" colspan="2"><input type="checkbox">Người nhận TT</label></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style="width:100%;border-top-style: solid;border-top-width: 1px;">
                            <tbody>
                                <tr><td style="padding-bottom: 5px;padding-top:5px;"><b>GHI CHÚ<b></b></b></td></tr>
                                <tr><td style="word-wrap: break-word;overflow: hidden;height: 50px;vertical-align:top;">${
                                  item.Notes
                                }</td></tr>
                            </tbody>
                        </table>
                        <table style="width:100%;border-top-style: solid;border-top-width: 1px; padding-top:10px">
                            <tbody>
                                <tr>
                                    <td class="table-vertical" style="width:50%;vertical-align:top;">
                                        <table style="font-size:18px;width:100%;">
                                            <tbody>
                                                <tr><td style="text-align:center;">Ngày ........ / ........ / ${FormatDateJson(
                                                  new Date(),
                                                  "y"
                                                )}</td></tr>
                                                <tr><td style="text-align:center;"><b>Nhân viên phát</b><br>(Ký và ghi rõ họ tên)</td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td class="table-vertical" style="width:50%;vertical-align:top;height:210px;">
                                        <table style="font-size:18px;width:100%;">
                                            <tbody>
                                                <tr><td style="text-align:center;">Ngày ......./......./ ${FormatDateJson(
                                                  new Date(),
                                                  "y"
                                                )}</td></tr>
                                                <tr><td style="text-align:center;"><b>Ký nhận: </b><br>(Ký và ghi rõ họ tên)</td><td><br><br><br></td></tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="text-align:center"><b><i>Lưu ý : Nếu hàng hóa có dấu hiệu bất thường vui lòng ghi rõ vào bên trên</i></b></div>
                        <div style="text-align:center"><i>Hotline hỗ trợ: +84.938.53.22.26 (Ms.Hiền)</i></div>
                    </div>`;
        }
      }
    });
    if (_htmlprint === "") {
      Alerterror("Chưa chọn dữ liệu in");
    } else {
      let myWindow = window.open("", "_blank");
      myWindow.document.write(
        '<html><link href="https://fonts.googleapis.com/css?family=Libre Barcode 128 Text" rel="stylesheet"><body style="margin:0">' +
          _htmlprint +
          '<script type="text/javascript">window.focus(); setTimeout(function() { window.print();window.close(); }, 1000);</script></body></html>'
      );
    }
  };

  const Cancel = () => {
    setLadingList([]);
    //PaginationTable();
    setShowList("display-none");
    setShowForm("");
    setShowUploadFile("");
    setShowListFile("display-none");
  };

  return (
    <LayoutMain>
      <div className="main-panel">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-primary">
                    <div className="pull-left">
                      <h4 className="card-title">
                        <i className="material-icons">bubble_chart</i> Avery -
                        In bill
                      </h4>
                    </div>
                    <div className="pull-right">
                      <Link
                        className="titleButton"
                        download
                        target="_blank"
                        to="/assets/exceltemp/MauVanDonInAvery.xlsx"
                      >
                        <i className="material-icons">download</i> Tải file mẫu
                      </Link>
                    </div>
                  </div>
                  <div className="card-body">
                    <form className="form-horizontal">
                      <div className={ShowForm + " row"}>
                        <div className="col-md-3"></div>
                        <div className={ShowUploadFile + " col-md-6"}>
                          <div className="uploadFileContainer text-center">
                            <span>
                              <i className="material-icons size40">
                                cloud_upload
                              </i>
                            </span>
                            <span>Upload a File</span>
                            <span className="font-normal">Choose a file</span>
                            {/* <span className="font-normal">{FileUpload.name}</span> */}
                            <input
                              type="file"
                              className="fileUploadHidden"
                              onChange={onFileChange}
                              onClick={(e) => {
                                e.target.value = null;
                              }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                            {/* <input multiple="multiple" class="fileupload-input" id="input_5" type="file" name="file" aria-labelledby="label_5" aria-hidden="true" tabindex="-1" /> */}
                          </div>
                        </div>
                        <div className={ShowListFile + " col-md-6"}>
                          <div
                            className={
                              FileUpload.file !== {}
                                ? "fileNameUpload"
                                : "fileNameUpload display-none"
                            }
                          >
                            <span className="fileNameUpload">
                              {FileUpload.file !== {} && FileUpload.file !== []
                                ? FileUpload.file.name
                                : ""}
                            </span>
                            <i
                              className="material-icons pull-right pointer"
                              title="Xóa file"
                              onClick={(e) => {
                                setFileUpload({ file: {} });
                                setShowUploadFile("");
                                setShowListFile("display-none");
                              }}
                            >
                              delete
                            </i>
                            <span className="pull-right">
                              {"(" +
                                (FileUpload.file.size / 1024).toFixed(2) +
                                "KB)"}
                            </span>
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-danger"
                              disabled={!disable}
                              onClick={onFileUpload}
                            >
                              <i className="material-icons">cloud_upload</i> TẢI
                              LÊN
                            </button>
                          </div>
                          {/* <div className="errorUpload">{UploadError}</div> */}
                        </div>
                        <div className="col-md-3"></div>{" "}
                      </div>
                      <div className="clearfix"></div>
                    </form>
                    <div className={ShowList + " row"}>
                      <div className="col-md-12 text-center">
                        <input
                          type="number"
                          className="form-control height60"
                          ref={TotalSheetRef}
                          value={TotalSheet}
                          {...bindTotalSheet}
                          min="1"
                        />
                        <button
                          type="button"
                          className="btn btn-default pull-right"
                          onClick={Cancel}
                        >
                          <i className="material-icons">undo</i>
                          Hủy
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger pull-right"
                          disabled={!disable}
                          onClick={PrintBill}
                        >
                          <i className="material-icons">check</i> Xác nhận in
                        </button>
                      </div>
                      <div className="col-md-12 mt30">
                        <div className="table-responsive">
                          <table id="dataTable" className="table text-center">
                            <thead className=" text-primary">
                              <tr>
                                <th class="table-checkbox">
                                  <div className="form-check">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="checkAll"
                                        checked={IsChecked}
                                        onChange={handleChange}
                                      />
                                      <span className="form-check-sign">
                                        <span className="check"> </span>
                                      </span>
                                    </label>
                                  </div>
                                </th>
                                <th>NO</th>
                                <th>DATE</th>
                                <th>CUSTOMER</th>
                                <th>ADDRESS</th>
                                <th>CARTON SIDE</th>
                                <th>SERVICE</th>
                                <th>KEEPER</th>
                                <th>DELIVERY NUMBER</th>
                                <th>NOTES</th>
                                <th>BILL</th>
                                <th>ATTN</th>
                                <th>KG</th>
                                <th>FILE SCAN</th>
                              </tr>
                            </thead>
                            <tbody>
                              {LadingList.map((item, index) => {
                                debugger;
                                return (
                                  <tr key={index}>
                                    <td class="table-checkbox">
                                      <div className="form-check">
                                        <label className="form-check-label">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={item.No}
                                            checked={item.IsPrint}
                                            onChange={handleChange}
                                          />
                                          <span className="form-check-sign">
                                            <span className="check"></span>
                                          </span>
                                        </label>
                                      </div>
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{FormatDate(item.Date, "d-k-y")}</td>
                                    <td>{item.Customer}</td>
                                    <td>{item.Address}</td>
                                    <td>{item.CartonSide}</td>
                                    <td>{item.DichVu}</td>
                                    <td>{item.Keeper}</td>
                                    <td>{item.TotalDN}</td>
                                    <td>{item.Notes}</td>
                                    <td>{item.Bill}</td>
                                    <td>{item.Attn}</td>
                                    <td>{item.Kg}</td>
                                    <td>{item.FileScan}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </LayoutMain>
  );
};
