export const DecodeString = (string) => {
  try {
    if (string.trim() !== "") {
      let a = atob(string);
      return decodeURIComponent(a);
    } else return "{}";
  } catch (err) {
    return "{}";
  }
};
