import React, { useEffect } from "react";
import { FooterMenu, HeaderMenu, LeftMenu } from "../Component/Template";
import { GetCookie } from "../Utils";

const LayoutMain = ({ children, ...rest }) => {
  useEffect(() => {
    let check = GetCookie("CustomerID");
    debugger
    if (check === null || check === undefined || check === "")
      window.location.href = "/";
  }, []);
  return (
    <>
      <LeftMenu></LeftMenu>
      <div className="main-panel ps">
        <HeaderMenu></HeaderMenu>
        <div className="content" style={{ height: "calc(100vh - 58px)" }}>
          {children}
        </div>
        <FooterMenu></FooterMenu>
      </div>
    </>
  );
};
export default LayoutMain;
