let modules = [{ "MenuId": 1250, "ActionName": "\/" },
    { "ModuleName": "WEBC", "MenuId": 1251, "ActionName": "\/register" },
    { "ModuleName": "WEBC", "MenuId": 1252, "ActionName": "\/home" },
    { "ModuleName": "WEBC", "MenuId": 1253, "ActionName": "\/lo-trinh-giao-hang" },
    { "ModuleName": "WEBC", "MenuId": 1254, "ActionName": "\/forgot" },
    { "ModuleName": "WEBC", "MenuId": 1255, "ActionName": "\/reset" },
    { "ModuleName": "WEBC", "MenuId": 1256, "ActionName": "\/thong-tin-ca-nhan" },
    { "ModuleName": "WEBC", "MenuId": 1257, "ActionName": "\/doi-mat-khau" },
    { "ModuleName": "WEBC", "MenuId": 1258, "ActionName": "\/dia-chi-nhan-thuong-xuyen" },
    { "ModuleName": "WEBC", "MenuId": 1259, "ActionName": "\/dia-chi-gui-thuong-xuyen" },
    { "ModuleName": "WEBC", "MenuId": 1260, "ActionName": "\/xac-thuc-tai-khoan" },
    { "ModuleName": "WEBC", "MenuId": 1261, "ActionName": "\/mang-luoi-buu-cuc" },
    { "ModuleName": "WEBC", "MenuId": 1262, "ActionName": "\/cong-no-tam-tinh" },
    { "ModuleName": "WEBC", "MenuId": 1263, "ActionName": "\/thoi-gian-toan-trinh-phat-hang-dich-vu" },
    { "ModuleName": "WEBC", "MenuId": 1264, "ActionName": "\/ho-tro-don-hang" },
    { "ModuleName": "WEBC", "MenuId": 1265, "ActionName": "\/bao-cao-nhap-kho" },
    { "ModuleName": "WEBC", "MenuId": 1266, "ActionName": "\/bao-cao-xuat-kho" },
    { "ModuleName": "WEBC", "MenuId": 1267, "ActionName": "\/bao-cao-chuyen-kho" },
    { "ModuleName": "WEBC", "MenuId": 1268, "ActionName": "\/bao-cao-ton-kho" },
    { "ModuleName": "WEBC", "MenuId": 1269, "ActionName": "\/bao-cao-ton-kho-ncc" },
    { "ModuleName": "WEBC", "MenuId": 1270, "ActionName": "\/bao-cao-ton-kho-npt" },
    { "ModuleName": "WEBC", "MenuId": 1271, "ActionName": "\/bao-cao-tong-quan" },
    { "ModuleName": "WEBC", "MenuId": 1272, "ActionName": "\/bao-cao-dealine-thanh-toan" },
    { "ModuleName": "WEBC", "MenuId": 1273, "ActionName": "\/bao-cao-kpi-giao-hang-cod" },
    { "ModuleName": "WEBC", "MenuId": 1274, "ActionName": "\/bao-cao-ton-no" },
    { "ModuleName": "WEBC", "MenuId": 1275, "ActionName": "\/bang-ke-thanh-toan" },
    { "ModuleName": "WEBC", "MenuId": 1276, "ActionName": "\/bang-ke-thanh-toan-cod" },
    { "ModuleName": "WEBC", "MenuId": 1277, "ActionName": "\/danh-sach-ho-tro-don-hang" },
    { "ModuleName": "WEBC", "MenuId": 1278, "ActionName": "\/tao-nhanh-van-don" },
    { "ModuleName": "WEBC", "MenuId": 1279, "ActionName": "\/tim-kiem-van-don" },
    { "ModuleName": "WEBC", "MenuId": 1280, "ActionName": "\/tra-cuu-van-don" },
    { "ModuleName": "WEBC", "MenuId": 1281, "ActionName": "\/upload-excel-van-don" },
    { "ModuleName": "WEBC", "MenuId": 1282, "ActionName": "\/in-bill-tu-excel" },
    { "ModuleName": "WEBC", "MenuId": 1283, "ActionName": "\/uoc-tinh-cuoc-phi" },
    { "ModuleName": "WEBC", "MenuId": 1284, "ActionName": "\/print-bill" }
    ];
export const GetModule = (url) => {
    return modules.find(p=>p.ActionName===url);
};